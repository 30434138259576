<template>
	<w-menu v-model="menu" :close-on-content-click="false" full-width lazy max-width="290px" min-width="290px" offset-y transition="scale-transition">
		<template v-slot:activator="{ on }">
			<w-flex shrink>
				<w-text-input v-model="displayedTime" prepend-icon="schedule" readonly v-on="on"></w-text-input>
			</w-flex>
		</template>
		<v-time-picker v-if="menu" v-model="time" format="24hr" color="primary" full-width @click:minute="closeMenu()"></v-time-picker>
	</w-menu>
</template>

<script>
export default {
	name: 'WTimeInput',
	props: {
		value: {
			default: undefined,
			required: false,
			type: [String]
		}
	},
	data: function () {
		return {
			menu: false
		}
	},
	computed: {
		displayedTime: function () {
			return this.time.split(':')[0] + 'h' + this.time.split(':')[1]
		},
		time: {
			get: function () {
				return this.value
			},
			set: function (val) {
				this.$emit('input', val)
			}
		}
	},
	methods: {
		closeMenu: function () {
			this.menu = false
		}
	}
}
</script>
